import { getCacheConfig } from "./Cache";
import { API_ENDPOINT } from "./constants";
import { IAttachment } from "./interfaces/IAttachment";
import { IPasswordData } from "./interfaces/IPasswordData";
import { http, HttpResponse } from "./MetadactCore";

async function getProfiles(): Promise<any> {
  const requestParameter: RequestInit = {
    method: "GET",
    redirect: "follow",
    headers: {
      Accept: "application/json",
    },
  };
  const config = await getCacheConfig();

  const GET_PROFILES_URL = `${config.serverUrl_v2}${API_ENDPOINT.PROFILES}`;

  return http(new Request(GET_PROFILES_URL, requestParameter));
}

async function fetchProfileInformation(profileId: string): Promise<any> {
  const requestParameter: RequestInit = {
    method: "GET",
    redirect: "follow",
    headers: {
      Accept: "application/json",
    },
  };
  const config = await getCacheConfig();

  const GET_PROFILES_URL = `${config.serverUrl_v2}${API_ENDPOINT.PROFILES}/${profileId}`;

  return http(new Request(GET_PROFILES_URL, requestParameter));
}

async function fetchProfileInformationByName(name: string): Promise<any> {
  const requestParameter: RequestInit = {
    method: "GET",
    redirect: "follow",
    headers: {
      Accept: "application/json",
    },
  };
  const config = await getCacheConfig();

  const GET_PROFILES_URL = `${config.serverUrl_v2}${API_ENDPOINT.PROFILE_SEARCH}?name=${name}`;

  return http(new Request(GET_PROFILES_URL, requestParameter));
}

async function createSession(
  instructions?: JSON,
  passwordData?: IPasswordData[],
  isBase64Content?: boolean,
  isCleaning?: boolean,
  applicationName?: string
) {
  let body: any = {
    instructions: { ...instructions },
  };
  if (isBase64Content) {
    body = { ...body, isBase64Content };
  }
  if (isCleaning) {
    body = { ...body, isCleaning };
  }
  if (applicationName) {
    body = { ...body, applicationName };
  }
  if (passwordData) {
    body = {
      ...body,
      passwordData: {
        passwords: [...passwordData],
      },
    };
  }

  const requestParameter: RequestInit = {
    method: "POST",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  const config = await getCacheConfig();

  const CREATE_NEW_SESSION = `${config.serverUrl_v2}${API_ENDPOINT.SESSIONS}`;

  return http(new Request(CREATE_NEW_SESSION, requestParameter));
}

async function uploadOriginalDocument<T>(sessionId: string, attachment: IAttachment): Promise<HttpResponse<T>> {
  const formData = new FormData();
  const blob: Blob = new Blob([attachment.content], {
    type: `${attachment.contentType ?? "application/json"}; encoding=base64`,
  });
  formData.append("file", blob, attachment.name);
  const config = await getCacheConfig();
  const path = `${config.serverUrl_v2}${API_ENDPOINT.SESSIONS}/${sessionId}/Original`;
  const args: RequestInit = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };
  return http<T>(new Request(path, args));
}

async function getReportForSession<T>(sessionId: string): Promise<HttpResponse<T>> {
  const requestParameter: RequestInit = {
    method: "GET",
  };
  const config = await getCacheConfig();

  const GET_REPORT = `${config.serverUrl_v2}${API_ENDPOINT.SESSIONS}/${sessionId}/Report`;

  return http<T>(new Request(GET_REPORT, requestParameter));
}

async function getSessionState(sessionId: string) {
  const requestParameter: RequestInit = {
    method: "GET",
  };
  const config = await getCacheConfig();

  const GET_REPORT = `${config.serverUrl_v2}${API_ENDPOINT.SESSIONS}/${sessionId}`;

  return http(new Request(GET_REPORT, requestParameter));
}

async function getCleanedDocument<T>(sessionId: string) {
  const requestParameter: RequestInit = {
    method: "GET",
  };
  const config = await getCacheConfig();
  const GET_REPORT = `${config.serverUrl_v2}${API_ENDPOINT.SESSIONS}/${sessionId}/Cleaned`;

  return http<T>(new Request(GET_REPORT, requestParameter));
}

export {
  getProfiles,
  fetchProfileInformation,
  fetchProfileInformationByName,
  createSession,
  uploadOriginalDocument,
  getReportForSession,
  getSessionState,
  getCleanedDocument,
};
