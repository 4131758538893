export function getAttachments(item) {
  return new Promise<any>((resolve) => {
    item.getAttachmentsAsync((response) => resolve(response));
  });
}

export function getAttachmentContent(attachmentId) {
  const item = Office.context.mailbox.item;

  return new Promise<any>((resolve) => {
    item.getAttachmentContentAsync(attachmentId, (response) => resolve(response));
  });
}

export function displayDialog(url, dialogOptions) {
  return new Promise<any>((resolve) => {
    Office.context.ui.displayDialogAsync(url, dialogOptions, (response) => resolve(response));
  });
}

export function getToEmails(item) {
  return new Promise<any>((resolve) => {
    item.to.getAsync((response) => resolve(response));
  });
}

export function getCcEmails(item) {
  return new Promise<any>((resolve) => {
    item.cc.getAsync((response) => resolve(response));
  });
}

export function getBccEmails(item) {
  return new Promise<any>((resolve) => {
    item.bcc.getAsync((response) => resolve(response));
  });
}

export function getOptionalAttendees(item) {
  return new Promise<any>((resolve) => {
    item.optionalAttendees.getAsync((response) => resolve(response));
  });
}

export function getRequiredAttendees(item) {
  return new Promise<any>((resolve) => {
    item.requiredAttendees.getAsync((response) => resolve(response));
  });
}

// Removes the specified attachment from the composed item.
export async function removeAttachment(attachmentId) {
  return new Promise<any>((resolve) => {
    Office.context.mailbox.item.removeAttachmentAsync(attachmentId, { asyncContext: null }, (response) =>
      resolve(response)
    );
  });
}

// Removes the specified attachment from the composed item.
export async function addAttachment(base64File, name) {
  return new Promise<any>((resolve) => {
    Office.context.mailbox.item.addFileAttachmentFromBase64Async(
      base64File,
      name,
      { asyncContext: null, isInline: false },
      (response) => resolve(response)
    );
  });
}

export async function saveRoamingSettings() {
  return new Promise<any>((resolve) => {
    Office.context.roamingSettings.saveAsync((response) => resolve(response));
  });
}

export async function loadCachedCustomProperties() {
  return new Promise<any>((resolve) => {
    Office.context.mailbox.item.loadCustomPropertiesAsync((response) => resolve(response));
  });
}

export async function saveCustomProp(customProps) {
  return new Promise<any>((resolve) => {
    customProps.saveAsync((response) => resolve(response));
  }).catch((error) => {
    console.error(error);
  });
}
