const API_ENDPOINT = {
  PROFILES: "/profiles",
  PROFILE_SEARCH: "/profiles/search",
  SESSIONS: "/sessions",
};

const PROCESSING_RESULT = {
  InvalidOpenPassword: "InvalidOpenPassword",
  InvalidModifyPassword:"InvalidModifyPassword",
  CorruptedDocument:"CorruptedDocument",
  ProcessingTerminated:"ProcessingTerminated",
  InvalidFileFormat:"InvalidFileFormat",
  DigitallySignedError:"DigitallySignedError",
  UsptoFileError:"UsptoFileError",
  PdfaFileError:"PdfaFileError",
  FileIsProtectedWithAip:"FileIsProtectedWithAip"
};

const INTERVAL_SESSION_FETCH = 1500;

export { API_ENDPOINT, INTERVAL_SESSION_FETCH, PROCESSING_RESULT };
