export enum DialogAction {
  Send = "send",
  CloseDialog = "closeDialog",
  GetPassword = "getPassword",
}

export type CleaningOption = {
  caption: string;
  profileName: string;
  id: string;
  name: string;
  skipCleaning?: boolean;
};

export type Config = {
  serverUrl: string;
  defaultOption: string;
  enableCleaning: boolean;
  specificDomains: string[];
  allowToSkipCleaning: boolean;
  persistUserChanges: boolean;
  developerMode: boolean;
  options: CleaningOption[];
  serverUrl_v2: string;
};
