import {
  cacheCustomPropAttachment,
  cacheCustomPropAttachments,
  getCacheConfig,
  getCachedAttachments,
  getUserEnableCleaning,
} from "../core/Cache";
import { IAttachment } from "../core/interfaces/IAttachment";
import { IProfile } from "../core/interfaces/IProfile";
import { IProfileOption } from "../core/interfaces/IProfileOption";
import {
  createSession,
  fetchProfileInformationByName,
  getCleanedDocument,
  getReportForSession,
  getSessionState,
  uploadOriginalDocument,
} from "../core/MetadactApi";
import { BodyResponse, /* clean,  discover,*/ HttpResponse } from "../core/MetadactCore";
import { CleaningOption, DialogAction } from "../types";
import {
  addAttachment,
  displayDialog,
  getAttachmentContent,
  getAttachments,
  getBccEmails,
  getCcEmails,
  getOptionalAttendees,
  getRequiredAttendees,
  getToEmails,
  removeAttachment,
} from "./callbacksToPromises";
import translation from "./translation";

import { INTERVAL_SESSION_FETCH, PROCESSING_RESULT } from "../core/constants";
import { MetadataReport, ProcessingDetails } from "../core/interfaces/IReportResponse";
import { PasswordRequiredException } from "../core/exceptions/PasswordRequiredException";
import { CorruptedFileException } from "../core/exceptions/CorruptedFileException";
import { CleaningException } from "../core/exceptions/CleaningException";

const supportExtensionsTable = [
  "doc",
  "docx",
  "docm",
  "dot",
  "dotx",
  "dotm",
  "xls",
  "xlsx",
  "xlsm",
  "xlsb",
  "xlt",
  "xltx",
  "xltm",
  "ppt",
  "pptx",
  "pptm",
  "pot",
  "potx",
  "potm",
  "pps",
  "ppsx",
  "ppsm",
  "pdf",
  "rtf",
  "jpg",
  "jpeg",
  "tiff",
  "tif",
  "bmp",
  "png",
  "gif",
  "xps",
  "oxps",
];

export function getRiskLevel(processingDetails: ProcessingDetails): string {
  if (!processingDetails?.riskLevel) {
    return translation.low;
  }

  if (typeof processingDetails?.riskLevel === "string") {
    return processingDetails?.riskLevel;
  }
  const riskLevel = processingDetails.riskLevel;
  if (riskLevel > 70) {
    return translation.high;
  }
  if (riskLevel > 28) {
    return translation.medium;
  }
  return translation.low;
}

export function getContentRisk(metadataReport?: MetadataReport): string {
  const contentRiskTable = {
    word: ["comments", "trackedChanges"],
    excel: ["comments", "trackedChanges", "hiddenColumns", "hiddenRows", "hiddenSheets", "formulas"],
    powerPoint: ["comments", "notes"],
  };
  const contentRisk: String[] = [];

  if (!metadataReport) {
    return translation.low;
  }
  for (const [metadataReportKey, metadataReportValue] of Object.entries(metadataReport)) {
    if (metadataReportValue === null) {
      continue;
    }
    for (const [contentRiskTableKey, contentRiskTableValue] of Object.entries(contentRiskTable)) {
      if (metadataReportKey === contentRiskTableKey) {
        contentRiskTableValue.forEach((contentName) => {
          const contentObj = metadataReportValue[contentName];
          if (contentObj) {
            if (contentObj.values.length > 1 || contentObj.values[0].value !== "0") {
              contentRisk.push(contentObj.friendlyName);
            }
          }
        });
      }
    }
  }

  return contentRisk.length ? contentRisk.join(", ") : translation.low;
}

export function isRiskyDomain(email: string, riskyDomains: string[]): boolean {
  if (Array.isArray(riskyDomains)) {
    const emailDomain = email.split("@").pop();
    return riskyDomains.some((domain) => domain.toLowerCase() === emailDomain.toLowerCase());
  } else {
    return false;
  }
}

export function isExtensionSupported(fileName: string): boolean {
  const fileExt = fileName.split(".").pop();
  if (!fileExt) {
    return false;
  }
  if (fileExt === fileName) {
    return false;
  }
  return supportExtensionsTable.some((extension) => {
    return extension.toLowerCase() === fileExt.toLowerCase();
  });
}

export function getRiskyEmailAddresses(emails: { emailAddress: string }[], riskyDomains: string[]): string[] {
  const riskyEmailAddresses = [];
  if (!Array.isArray(emails) || !emails || !riskyDomains) {
    return [];
  }
  for (const email of emails) {
    if (isRiskyDomain(email.emailAddress, riskyDomains)) {
      riskyEmailAddresses.push(email.emailAddress);
    }
  }
  return riskyEmailAddresses;
}

export async function showInformationalMessage() {
  let id = "userEnableCleaning";
  Office.context.mailbox.item.notificationMessages.removeAsync(id);

  let enableCleaning = await getUserEnableCleaning();
  const details = {
    type: "informationalMessage",
    message: enableCleaning ? translation.addInMessageEnabled : translation.addInMessageDisabled,
    icon: "litera-logo",
    persistent: false,
  };

  Office.context.mailbox.item.notificationMessages.addAsync(id, details);
}

// will only return true if Outlook is running in web browser
export function checkIsOfficeOnline(): boolean {
  return Office.context.platform === Office.PlatformType.OfficeOnline;
}

export function msToSeconds(ms: number): number {
  return Math.ceil(ms / 1000);
}

// adds trailing zero infront of single-digit numbers
function addTrailingZero(value: number): string {
  return value.toString().padStart(2, "0");
}

// converts seconds to 'mm:ss' format
export function formatSeconds(rawSeconds: number): string {
  const minutes = addTrailingZero(Math.floor((rawSeconds % 3600) / 60));
  const seconds = addTrailingZero(Math.floor(rawSeconds % 60));

  return `${minutes}:${seconds}`;
}

export async function getEmailWarnings(emailItem: any, riskyDomains: string[]): Promise<string[]> {
  let emailsWarnArray: string[] = [];

  if (emailItem.to) {
    const toEmails = await getToEmails(emailItem);

    emailsWarnArray = getRiskyEmailAddresses(toEmails.value, riskyDomains);
  }

  if (emailItem.cc) {
    const ccEmails = await getCcEmails(emailItem);

    emailsWarnArray = [...emailsWarnArray, ...getRiskyEmailAddresses(ccEmails.value, riskyDomains)];
  }

  if (emailItem.bcc) {
    const bccEmails = await getBccEmails(emailItem);

    emailsWarnArray = [...emailsWarnArray, ...getRiskyEmailAddresses(bccEmails.value, riskyDomains)];
  }

  if (emailItem.optionalAttendees) {
    const optionalAttendees = await getOptionalAttendees(emailItem);

    emailsWarnArray = [...emailsWarnArray, ...getRiskyEmailAddresses(optionalAttendees.value, riskyDomains)];
  }

  if (emailItem.requiredAttendees) {
    const requiredAttendees = await getRequiredAttendees(emailItem);

    emailsWarnArray = [...emailsWarnArray, ...getRiskyEmailAddresses(requiredAttendees.value, riskyDomains)];
  }

  return emailsWarnArray;
}

export function getMappedProfileOptions(profiles: IProfile[], configOptions: CleaningOption[]): IProfileOption[] {
  return configOptions.map((_configOption) => {
    try {
      const matchedProfileFromConfig = profiles.filter(
        (item) => item.name.toLocaleLowerCase() == _configOption.profileName.toLocaleLowerCase()
      );
      if (matchedProfileFromConfig.length === 0) {
        throw Error(`no matching profile option available for configuration option : ${_configOption.profileName}`);
      }
      return { ..._configOption, ...matchedProfileFromConfig[0] };
    } catch (error) {
      console.error(error?.message);
    }
    return { ..._configOption };
  });
}

/**
 *
 * @param isWarningDialog
 * @returns Promise<IDialogResponse> it will return the dialog instance and the message it receive.
 */
async function openDialog(isWarningDialog?: boolean): Promise<Office.Dialog> {
  const url = `${window.location.origin}/dialog.html`;
  const dialogOptions = isWarningDialog
    ? { width: 20, height: 20, displayInIframe: true }
    : { width: 40, height: 60, displayInIframe: true };
  const responseDialog = await displayDialog(url, dialogOptions);
  const dialog = responseDialog.value;
  return Promise.resolve(dialog);
}

function getAttachment(id: string, attachments: IAttachment[]) {
  return attachments ? attachments.find((attachment) => attachment.id === id) : undefined;
}

function updateAttachmentOnSuccessResponse(
  attachment: IAttachment,
  riskLevel: string,
  contentRisk: string,
  password?: string
) {
  if (password) {
    attachment.password = password;
  }

  attachment.riskLevel = riskLevel;
  attachment.contentRisk = contentRisk;
  attachment.errorMsg = "";
  attachment.passwordError = false;
  attachment.error = false;

  return attachment;
}

function updateAttachmentWithError(
  attachment: IAttachment,
  errorMsg: string,
  isPasswordError?: boolean,
  isPasswordIncorrect?: boolean,
  newRiskLevel?: string
) {
  attachment.riskLevel = translation.high;
  attachment.passwordError = false;
  attachment.error = true;

  if (isPasswordError) {
    attachment.riskLevel = translation.notAvailable;
    attachment.passwordError = true;
    attachment.error = false;
  }

  if (isPasswordIncorrect) {
    attachment.passwordIncorrect = true;
  }

  if (newRiskLevel) {
    attachment.riskLevel = newRiskLevel;
  }

  attachment.errorMsg = errorMsg;
  attachment.option = "skip";

  return attachment;
}

/**
 * This function is useful for the processing dialog handling
 * @param response response received from the dialog
 */
async function dialogMessageReceivedEventHandler(
  output: { response: string; dialog: any },
  event: Office.AddinCommands.Event
) {
  const message = JSON.parse(output.response);
  console.trace(`dialogMessageReceivedEventHandler : ${message}`);

  if (message.dialogReady) {
    await handleDialogReadyEvent(output.dialog);
  }

  if (message.action === DialogAction.CloseDialog) {
    return closeDialog(output.dialog, event, false);
  }
  if (message.action === DialogAction.GetPassword) {
    const attachmentsToSend = await getProcessedMailboxAttachments();
    return handleDialogActionGetPassword(message, [...attachmentsToSend] /*, output.dialog*/);
  }
  if (message.action === DialogAction.Send) {
    handleDialogActionSend(message, output.dialog, event);
  }
}

/**
 * if password received for the file, resend for processing with that
 * password and process attachment again.
 * @param message
 * @param attachments
 */
async function handleDialogActionGetPassword(message: any, attachments: IAttachment[] /*, dialog: Office.Dialog*/) {
  console.info(`handleDialogActionGetPassword`);
  const cachedAttachment = getAttachment(message.attachmentId, attachments);

  if (!cachedAttachment) {
    return undefined;
  }
  // TODO : to handle the password protected file
  // confirmPassword(cachedAttachment, message.password, dialog);
}

/*async function confirmPassword(attachment, password, dialog: Office.Dialog) {
  console.info(`confirmPassword ${attachment} ${password}`);

  const config = await getCacheConfig();

  // process the non cloud files and download the content
  const responseAttachmentContent = await getAttachmentContent(attachment.id);

  // get profile default instructions from server
  const profile = await fetchProfileInformationByName(config.defaultOption);
  const metadataSettings = profile["parsedBody"]["metadataSettings"];

  // make a discover or session request to get document metadata.
  // const responseDiscover = await discoverRequest(
  //   dialog,
  //   {
  //     ...attachment,
  //     content: responseAttachmentContent.value.content,
  //   },
  //   JSON.stringify({ metadataSettings: metadataSettings }),
  //   password
  // );

  await handleDiscoverResponse(responseDiscover, attachment, dialog);
}*/

async function dialogCreator(event: any, isWarningDialog?: boolean) {
  const dialog = await openDialog(isWarningDialog);
  dialog.addEventHandler(Office.EventType.DialogEventReceived, (): void => {
    console.info(`DialogEventReceived`);
    closeDialog(dialog, event, false);
  });

  dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args: any): void => {
    console.info(`DialogMessageReceived`);

    dialogMessageReceivedEventHandler({ response: args?.message, dialog: dialog }, event);
  });
  return dialog;
}

export async function getProfile(name: string): Promise<HttpResponse<any>> {
  console.info(`getProfile ${name}`);
  try {
    return await fetchProfileInformationByName(name);
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function sendForCleaning(sessionId: string) {
  console.info(`sendForCleaning ${sessionId}`);
  try {
    if (!sessionId) {
      throw new Error(`session must exist`);
    }
    return await getCleanedDocument<any>(sessionId);
  } catch (error) {
    console.log(`error while cleaning ${error}`);
    return null;
  }
}

async function getProcessedMailboxAttachments() {
  const mailboxItem = Office.context.mailbox.item;

  const config = await getCacheConfig();

  // get attachments ids and names to show in dialog
  const attachmentsFromMailBox = await getAttachments(mailboxItem);
  return processMailBoxAttachmentResponse(attachmentsFromMailBox, config.defaultOption);
}
export function getExtension(fileName) {
  return fileName.split(".").pop();
}

async function handleDialogReadyEvent(dialog: Office.Dialog) {
  const mailboxItem = Office.context.mailbox.item;
  const config = await getCacheConfig();
  // get attachments ids and names to show in dialog
  const attachmentsFromMailBox = await getAttachments(mailboxItem);
  const attachments = processMailBoxAttachmentResponse(attachmentsFromMailBox, config.defaultOption);

  let unSupportedExtensionsFromAttachments: string[] = attachmentsFromMailBox.value.map((attachment) => {
    if (!isExtensionSupported(attachment.name)) {
      return getExtension(attachment.name);
    }
    return undefined;
  });

  unSupportedExtensionsFromAttachments = unSupportedExtensionsFromAttachments.filter((value) => Boolean(value));

  if (Boolean(unSupportedExtensionsFromAttachments.length)) {
    dialog.messageChild(
      JSON.stringify({
        error: {
          errorMessage: `File type: ${unSupportedExtensionsFromAttachments
            .filter((attachment) => attachment)
            .join(", ")} not supported. ${translation.checkFileAndTryAgain}`,
        },
      })
    );
  }

  if (config.enableCleaning) {
    // get the email warnings for mail item
    const mailboxItem = Office.context.mailbox.item;
    const emailWarnings = await getEmailWarnings(mailboxItem, config.specificDomains);

    dialog.messageChild(
      JSON.stringify({
        emailsWarnArray: emailWarnings,
        attachmentArray: attachments,
        isWarningDialog: false,
      })
    );

    await handleCleaning(dialog, attachments /*, emailWarnings /*isWarningDialog*/);
  }
}

function closeDialog(dialog: Office.Dialog, event: Office.AddinCommands.Event, allowSend: boolean) {
  console.info(`closeDialog`);
  dialog.close();
  event.completed({ allowEvent: allowSend });
}

function processMailBoxAttachmentResponse(mailboxAttachment: any, profileName: string) {
  const unfilteredMailBoxAttachments = mailboxAttachment.value.map((attachmentInDialog: any) => {
    const attachmentType = attachmentInDialog.attachmentType;
    const isInlineAttachment = attachmentInDialog.isInline;

    if (isInlineAttachment) {
      return undefined;
    }

    if (!isExtensionSupported(attachmentInDialog.name)) {
      return undefined;
    }

    if (attachmentType == "item") {
      return undefined;
    }
    return {
      id: attachmentInDialog.id,
      name: attachmentInDialog.name,
      option: profileName,
      contentType: attachmentInDialog.contentType,
      attachmentType: attachmentType,
    };
  });
  return unfilteredMailBoxAttachments.filter((attachment: any) => attachment !== undefined);
}

function updateAttachments(attachment: IAttachment, attachments: IAttachment[]) {
  const index = attachments.findIndex(({ id }) => id === attachment.id);

  if (index === -1) {
    attachments.push(attachment);
  } else {
    attachments[index] = attachment;
  }
  return attachments;
}

async function processPasswordProtectedAttachments(attachments: IAttachment[]) {
  let cachedPasswordProtectedAttachments = await getCachedAttachments();
  let attachmentsInUse = cachedPasswordProtectedAttachments.map((attachment: IAttachment) => {
    let attachmentWithPasswordValue = getAttachment(attachment.id, attachments);

    if (!attachmentWithPasswordValue) {
      return undefined;
    }
    attachmentWithPasswordValue.password = attachment.password;
    updateAttachments(attachmentWithPasswordValue, attachments);
    return attachment;
  });

  attachmentsInUse = attachmentsInUse.filter((attachment: IAttachment) => attachment !== undefined);

  if (attachmentsInUse.length !== cachedPasswordProtectedAttachments.length) {
    cacheCustomPropAttachments(attachmentsInUse);
  }
}

function splitIn(attachments: IAttachment[], numberOfItems: number) {
  let containerArray = [];

  while (attachments.length > 0) {
    if (attachments.length >= numberOfItems) {
      containerArray.push(attachments.splice(0, numberOfItems));
    } else {
      containerArray.push([...attachments]);
      break;
    }
  }
  return containerArray;
}

function handleCloudAttachment(attachment: IAttachment, dialog: Office.Dialog): IAttachment {
  const updateAttachment = updateAttachmentWithError(
    attachment,
    translation.cloudFileError,
    false,
    false,
    translation.notSupported
  );

  dialog.messageChild(
    JSON.stringify({
      error: { errorMessage: translation.errorMessageAnalyzeAttachments },
      attachment: { ...updateAttachment, progress: 1 },
    })
  );
  return updateAttachment;
}

interface StepResult {
  attachments: IAttachment[];
  attachment: IAttachment;
  progress: number;
  isSuccessful: boolean;
  response: HttpResponse<any>;
}

function step_i_session_creation(
  attachment: IAttachment,
  attachments: IAttachment[],
  shouldUseDiscoverProfile: boolean,
  dialog: Office.Dialog
): Promise<StepResult> {
  return new Promise((resolve, reject) => {
    if (!shouldUseDiscoverProfile) {
      // get profile default instructions from server
      getProfile(attachment.option)
        .then((response) => {
          const profileMetadata = checkErrors(response).parsedBody;
          createSession({ ...profileMetadata }, [], true, true, "0365")
            .then((response) => {
              checkErrors(response);
              // inform the progress of the attachment.
              dialog.messageChild(
                JSON.stringify({
                  attachment: { ...attachment, progress: 0.25 },
                })
              );
              resolve({
                attachment: { ...attachment, progress: 0.25 },
                attachments,
                progress: 0.25,
                isSuccessful: true,
                response,
              });
            })
            .catch((error) => {
              handleError(attachment, attachments, dialog, error);
              reject(`createSession : ${error?.message}`);
            });
        })
        .catch((error) => {
          handleError(attachment, attachments, dialog, error);
          reject(error);
        });
      return;
    }

    createSession({ ...require("../../src/profiles/discover.json") }, [], true, true, "0365")
      .then((response) => {
        checkErrors(response);
        dialog.messageChild(
          JSON.stringify({
            attachment: { ...attachment, progress: 0.25 },
          })
        );
        resolve({ attachment, attachments, progress: 0.25, isSuccessful: true, response });
      })
      .catch((error) => {
        handleError(attachment, attachments, dialog, error);
        reject(error);
      });
  });
}

function step_ii_upload_document_for_processing(
  attachment: IAttachment,
  attachments: IAttachment[],
  sessionId: string,
  dialog: Office.Dialog
): Promise<StepResult> {
  return new Promise((resolve, reject) => {
    uploadOriginalDocument(sessionId, { ...getAttachment(attachment.id, attachments) })
      .then((response) => {
        checkErrors(response);
        dialog.messageChild(
          JSON.stringify({
            attachment: { ...attachment, progress: 0.5 },
          })
        );
        resolve({ attachment, attachments, progress: 0.5, isSuccessful: true, response });
      })
      .catch((error) => {
        handleError(attachment, attachments, dialog, error);
        reject(error);
      });
  });
}

function step_iii_wait_for_processing_complete(
  attachment: IAttachment,
  attachments: IAttachment[],
  sessionId: string,
  dialog: Office.Dialog
): Promise<StepResult> {
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      let response = await getSessionState(`${sessionId}`);
      try {
        checkErrors(response);
        if (response?.parsedBody?.["sessionState"] === "Processed") {
          dialog.messageChild(
            JSON.stringify({
              attachment: { ...attachment, progress: 0.75 },
            })
          );
          resolve({ attachment, attachments, progress: 0.75, isSuccessful: true, response });
          clearInterval(intervalId);
        }
      } catch (error) {
        handleError(attachment, attachments, dialog, error);
        reject(error);
        clearInterval(intervalId);
      }
    }, INTERVAL_SESSION_FETCH);
  });
}

function step_iv_get_attachment_report(
  attachment: IAttachment,
  attachments: IAttachment[],
  sessionId: string,
  dialog: Office.Dialog
): Promise<StepResult> {
  return new Promise((resolve, reject) => {
    getReportForSession<any>(sessionId)
      .then((response) => {
        checkErrors(response);

        // response contains some data for processing.
        const riskLevel = getRiskLevel(response.parsedBody["processingDetails"]);
        const contentRisk = getContentRisk(response.parsedBody["metadataReport"]);
        const updatedAttachment = updateAttachmentOnSuccessResponse(
          { ...attachment, sessionId: sessionId },
          riskLevel,
          contentRisk,
          attachment?.password
        );
        updateAttachments(updatedAttachment, attachments);
        cacheCustomPropAttachment({ id: attachment.id, password: attachment?.password });
        dialog.messageChild(JSON.stringify({ attachment: { ...updatedAttachment, progress: 1 } }));
        resolve({ attachment, attachments, progress: 1, isSuccessful: true, response });
      })
      .catch((error) => {
        handleError(attachment, attachments, dialog, error);
        reject(error);
      });
  });
}

/**
 *
 * @param _attachments attachments for which report needs to be fetched.
 * @param dialog dialog instance.
 * @param shouldUseDiscoverProfile true to make discover profile call and
 * false when any other profile needs to be selected for attachment.
 * @returns processed attachments after reports or errors processed for the attachments.
 */
async function processReportsForAttachments(
  _attachments: any[],
  dialog: Office.Dialog,
  shouldUseDiscoverProfile?: boolean
): Promise<IAttachment[]> {
  // saving a copy of attachments
  let attachmentCopy = [..._attachments];
  const containerArray = attachmentCopy.length > 2 ? splitIn([..._attachments], 2) : [attachmentCopy];
  const attachmentWithFailedCleaning : IAttachment[]= [];

  return new Promise((resolve) => {
    let processedCount = 0;
    for (const attachments of containerArray) {
      attachments.forEach(async (attachment: IAttachment) => {
        try {
          // if cloud attachment, show error that it can not be processed.
          if (attachment.attachmentType === "cloud") {
            const updatedAttachment = handleCloudAttachment({ ...attachment }, dialog);
            updateAttachments(updatedAttachment, attachmentCopy);
            throw new Error(`cloud attachment ${attachment.name} could not be processed`);
          }

          // if skip profile is selected, need to iterate next item and
          // update the processed count.
          if (attachment.option === "skip") {
            throw new Error(`skip profile selected for ${attachment.name}`);
          }

          // process the non cloud files and download the content
          // and attachments updated with content downloaded.
          const responseAttachmentContent = await getAttachmentContent(attachment.id);
          updateAttachments(
            {
              ...attachment,
              content: responseAttachmentContent.value.content,
            },
            attachmentCopy
          );

          // STEP : 1 ,v2 version for the session endpoint that creates the session id.
          // we start with discover profile at first .

          const stepResult_i = await step_i_session_creation(
            attachment,
            attachmentCopy,
            shouldUseDiscoverProfile,
            dialog
          );
          console.log(`%c STEP 1 :${JSON.stringify(stepResult_i?.attachment.name)}`, "color: #d8a24f");

          // STEP : 2 , call the original endpoint for with this endpoint.
          const stepResult_ii = await step_ii_upload_document_for_processing(
            stepResult_i?.attachment,
            stepResult_i?.attachments,
            stepResult_i?.response?.parsedBody["id"],
            dialog
          );
          console.log(`%c STEP 2 :${JSON.stringify(stepResult_i?.attachment.name)}`, "color: #9e33a4");

          // STEP : 3, process the processing file response.
          // call  every once second.
          const stepResult_iii = await step_iii_wait_for_processing_complete(
            stepResult_ii.attachment,
            stepResult_ii.attachments,
            stepResult_ii.response?.parsedBody?.session?.["id"],
            dialog
          );
          console.log(`%c STEP 3 :${JSON.stringify(stepResult_iii?.attachment.name)}`, "color: blue");

          // STEP : 4 ,get the report for the attachment.
          // call the report endpoint for the file processing result.
          const stepResult_iv = await step_iv_get_attachment_report(
            stepResult_iii.attachment,
            stepResult_iii.attachments,
            stepResult_iii.response.parsedBody?.["id"],
            dialog
          );
          console.log(`%c STEP 4 :${JSON.stringify(stepResult_iv?.attachment.name)}`, "color: green");
        } catch (error) {
          console.log(`%c ERROR : ${error.message} ${attachment.sessionId} ${attachment.name}`, "color: red");

          if (error instanceof CleaningException) {
            attachmentWithFailedCleaning.push(attachment);

          }
        } finally {
          processedCount++;
          if (processedCount === attachmentCopy.length) {
            if(Boolean(attachmentWithFailedCleaning.length)) {
              dialog.messageChild(
                JSON.stringify({
                  error: {
                    errorMessage: `Cleaning operation failed on the following: ${attachmentWithFailedCleaning.map(item=> item.name).join(", ")} Check file and try again.`,
                  },
                })
              );
            }
            resolve(attachmentCopy);
          }
        }
      });
    }



  });
}

/**
 *
 * @param dialog dialog instance
 * @param attachments which are fetched and filtered from mailbox
 * @param emailWarnings warning related to email
 * @param isWarningDialog a condition based on which we want to show basic dialog or error dialog.
 */
async function handleCleaning(dialog: Office.Dialog, attachments: any /* emailWarnings: any /*isWarningDialog*/) {
  // process attachments with password
  await processPasswordProtectedAttachments(attachments);

  // upload the attachment for processing to backend
  await processReportsForAttachments(attachments, dialog, true);
}

async function handleDialogActionSend(message: any, dialog: Office.Dialog, event: Office.AddinCommands.Event) {
  console.info(`handleDialogActionSend`);
  if (!message.dialogAttachments) {
    return;
  }

  // upload the attachment for processing to backend
  processReportsForAttachments(message.dialogAttachments, dialog, false)
    .then(async (attachments) => {
      const response = await processCleaningForAttachments([...attachments], dialog);
      return response;
    })
    .then((isAllCleaned) => {
      console.log(`all cleaned properly ${isAllCleaned}`);
      return closeDialog(dialog, event, isAllCleaned);
    });
}

async function processCleaningForAttachments(attachments: IAttachment[], dialog: Office.Dialog): Promise<boolean> {
  console.log(`processCleaningForAttachments`);
  const cachedConfig = await getCacheConfig();

  return new Promise((resolve) => {
    let cleaningCount = 0;

    attachments.forEach(async (element) => {
      const dialogAttachment = attachments.find((item) => item.id == element.id);
      const attachmentProfileOption = dialogAttachment?.option;
      const attachmentError = dialogAttachment?.error;
      const shouldSkipCleaning = cachedConfig.options.some(
        (option) => option.profileName == attachmentProfileOption && option.skipCleaning
      );

      // update the progress and don't clean when error is there.
      // or skip cleaning option is selected and continue to next item.
      if (shouldSkipCleaning || attachmentError) {
        console.log(`skipped for cleaning ${element.name} ${element.sessionId}`);
        cleaningCount++;
        dialog.messageChild(JSON.stringify({ loadingPercent: cleaningCount / attachments.length }));
        if (attachments.length == cleaningCount) {
          resolve(true);
        }
        return undefined;
      }

      // process the option for cleaning if everything is good.
      // sent for cleaning via API.
      // after processing cleaning update the cleaningCount
      const responseCleanedDocument = await sendForCleaning(dialogAttachment.sessionId);
      await handleCleaningResponse({ ...responseCleanedDocument }, dialog, { ...dialogAttachment });
      cleaningCount++;

      dialog.messageChild(JSON.stringify({ loadingPercent: cleaningCount / attachments.length }));
      if (attachments.length == cleaningCount) {
        return resolve(true);
      }
    });
  });
}

async function handleCleaningResponse(
  response: HttpResponse<BodyResponse[]>,
  dialog: Office.Dialog,
  attachment: IAttachment
) {
  console.log(`handleCleaningResponse ${attachment.name} ${response.status}`);

  const responseRemoveAttachment = await removeAttachment(attachment.id);

  if (responseRemoveAttachment.status !== Office.AsyncResultStatus.Succeeded) {
    console.log(`removing attachment failed : ${attachment.id} ${attachment.name}`);
    updateAttachmentWithError(attachment, translation.errorMessageRemoveAttachment);

    dialog.messageChild(
      JSON.stringify({
        error: {
          errorMessage: translation.errorMessageRemoveAttachments,
        },
      })
    );
    return;
  }

  // all good when removing the attachment with metadata.
  // add the new cleaned attachment before sending.
  if (responseRemoveAttachment.status === Office.AsyncResultStatus.Succeeded) {
    console.log(`removing attachment succeed : ${attachment.id} ${attachment.name}`);
    const responseAddAttachment = await addAttachment(response.parsedBody["File"], attachment.name);

    if (responseAddAttachment.status != Office.AsyncResultStatus.Succeeded) {
      console.log(`adding attachment failed : ${attachment.id} ${attachment.name}`);
      updateAttachmentWithError(attachment, translation.errorMessageAttachAttachment);

      dialog.messageChild(
        JSON.stringify({
          error: {
            errorMessage: translation.errorMessageAttachAttachments,
          },
        })
      );
      return;
    }

    if (responseAddAttachment.status === Office.AsyncResultStatus.Succeeded) {
      console.log(`adding attachment succeed ${response.statusText} for ${attachment.name}`);
      return;
    }
  }
}

function checkErrors(response: HttpResponse<any> | null): HttpResponse<any> {
  if (!response) {
    throw new Error(`${translation.errorMessageCleaningFailed}`);
  }
  if (!response.parsedBody) {
    throw new Error(`${translation.errorMessageCleaningFailed}`);
  }
  if (response.parsedBody["passwordRequired"]) {
    throw new PasswordRequiredException(`${translation.passwordRequired}`);
  }
  if (response.parsedBody["processingResult"] == PROCESSING_RESULT.CorruptedDocument) {
    throw new CorruptedFileException();
  }
  if (!response.ok) {
    throw new CleaningException();
  }
  return response;
}

function handleError(attachment: IAttachment, attachmentCopy: IAttachment[], dialog: Office.Dialog, error: any) {
  if (error.message == "Failed to fetch") {
    // same error for attachment and top bar
    return dialog.messageChild(
      JSON.stringify({
        error: { errorMessage: translation.errorMessageConnectionLost },
        attachment: { ...attachment, progress: 1 },
      })
    );
  }

  if (error instanceof CorruptedFileException) {
    // Only show top message. Refer LINK {@link https://literadev.atlassian.net/browse/MDP-14374 }
    const updatedAttachment = { ...attachment, riskLevel: translation.corruptedFile, option: "skip" };
    updateAttachments({ ...updatedAttachment }, attachmentCopy);

    return dialog.messageChild(
      JSON.stringify({
        error: { errorMessage: error.message },
        attachment: { ...updatedAttachment, progress: 1 },
      })
    );
  }

  if (error instanceof PasswordRequiredException) {
    // besides the file passwordRequired
    const updatedAttachmentWithError = updateAttachmentWithError(attachment, error.message, true, true);
    updateAttachments({ ...updatedAttachmentWithError }, attachmentCopy);
    return dialog.messageChild(JSON.stringify({ attachment: { ...updatedAttachmentWithError, progress: 1 } }));
  }

  if(error instanceof CleaningException){
    const updatedAttachmentWithError = updateAttachmentWithError(attachment, error.message);
    updateAttachments({ ...updatedAttachmentWithError }, attachmentCopy);
    return dialog.messageChild(JSON.stringify({ attachment: { ...updatedAttachmentWithError, progress: 1 } }));
  }

  const _updatedAttachmentWithOtherError = updateAttachmentWithError(attachment, error.message);
  updateAttachments({ ..._updatedAttachmentWithOtherError }, attachmentCopy);
  return dialog.messageChild(
    JSON.stringify({
      error: { errorMessage: translation.errorMessageAnalyzeAttachments },
      attachment: { ..._updatedAttachmentWithOtherError, progress: 1 },
    })
  );
}

export {
  openDialog,
  getAttachment,
  updateAttachmentOnSuccessResponse,
  updateAttachmentWithError,
  handleDialogReadyEvent,
  dialogMessageReceivedEventHandler,
  processPasswordProtectedAttachments,
  closeDialog,
  dialogCreator,
  processMailBoxAttachmentResponse,
};
