import translation from "../../utils/translation";

class CleaningException extends Error {
    constructor(message?: string) {
      super(message);
      // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
      Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
      this.name = CleaningException.name; // stack traces display correctly now
      this.message = message ?? `${translation.errorMessageCleaningOperationFailed}`
    }
  }
  export { CleaningException };
  