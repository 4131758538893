const translation = {
  errorMessageAnalyzeAttachments: "Error occurred on analyze attachments",
  errorMessageAnalyzeAttachment: "Error occurred on analyze attachment",

  errorMessageCleanAttachments: "Error occurred on cleaning attachments",
  errorMessageCleanAttachment: "Error occurred on cleaning attachment",

  errorMessageRemoveAttachments: "Error occurred on remove not cleaned attachment",
  errorMessageRemoveAttachment: "Error occurred on remove not cleaned attachments",

  errorMessageAttachAttachments: "Error occurred on attache cleaned attachment",
  errorMessageAttachAttachment: "Error occurred on attache cleaned attachment",

  errorMessageCleaningFailed: "Cleaning operation failed. Check file and try again.",

  errorMessageConnectionLost: "Connection lost. Check connection and try again.",

  errorMessageCorruptedFile : "Corrupted file: Corrupted file(s) detected. Check file and try again." ,

  checkFileAndTryAgain : "Check file and try again.",

  errorMessageCleaningOperationFailed :'Cleaning operation failed',

  notSupported: "Not supported",
  notAvailable: "Not available",
  corruptedFile: "Corrupted file",
  danger: "Danger",

  high: "High",
  medium: "Medium",
  low: "Low",

  addInMessageEnabled: "Litera Metadact add-in is enabled",
  addInMessageDisabled: "Litera Metadact add-in is disabled",

  cloudFileError: "Currently, Documents from cloud storage are not supported.",
  passwordRequired: "Password required",
  close: "Close",

  warningRiskEmail: "Warning! You are sending email to potentially risk email",
  addresses: "addresses",
  address: "address",
  riskyDomain: "Risky domain",

  passwordWarningPartOne: "You didn't specify password for the following file(s):",
  passwordWarningPartTwo:
    "This file(s) will exclude from processing if you click Send. Enter password to select cleaning option for them. Click Cancel to close the dialog",

  cleaning: "Cleaning",

  tooltipSendDisabled: "This option is not available during cleaning and analysis.",
  send: "Send",
  cancel: "Cancel",

  tooltipOptionDisabled: "This option is not available during cleaning and analysis, and when a password is required.",
  cleaningOptions: "Cleaning Options to apply to all files",

  tooltipOptionPasswordDisabled: "Option is disabled on Cleaning,Analyze process and when password required",

  dialogLoading: "Dialog is loading...",

  passwordPlaceholder: "password",
  passwordNotCorrect: "Password is not correct",

  fileTypeName: "File Type",
  fileTypeDescription: "Column operations for File type, Press to sort on File type",

  fileName: "File",

  cleaningOption: "Cleaning Option",
  riskLevel: "Risk Level",
  contentRisk: "Content Risk",

  cleaningDescription: "Enable or disable cleaning attachments",
  notPermittedCleaning: "Cleaning option change is not permitted by admin",

  cleaningOn: "On",
  cleaningOff: "Off",

  isCurrentlyCahhed: "Config which is currently cached by add in",
  resetConfig: "Reset Config",
  saveConfig: "Save Config",
  warningDialogLifetime: "Dialog lifetime is limited in web browser. The dialog will be automatically closed in:",
};

export default translation;
