let customProps: Office.CustomProperties;
let persistUserFields = ["enableCleaning"];
import { saveRoamingSettings, loadCachedCustomProperties, saveCustomProp } from "../utils/callbacksToPromises";
import { Config } from "../types";

/**
 *
 * @returns config information fetched from the server or null
 * when error fetching config data.
 */
export async function fetchConfig(): Promise<Config> {
  try {
    return (
      await fetch("config.json", {
        headers: {
          "cache-control": "no-cache",
        },
      })
    ).json();
  } catch (error) {
    return null;
  }
}

export async function setEnableCleaning(enableCleaning: boolean) {
  let config = await getCacheConfig();
  config.enableCleaning = enableCleaning;
  await cacheConfig(config);
}

/**
 *
 * @returns should return the allowToSkipCleaning from cached version of the config.
 * if allowToSkipCleaning is true then user can change value of  enableCleaning
 *
 */
export async function getUserEnableCleaning(): Promise<boolean> {
  const config = await getCacheConfig();
  return config.enableCleaning;
}

/**
 *
 * @returns latest config updates from the server which updates cached config.
 */
export async function getConfig(): Promise<Config> {
  const cachedConfig = Office.context.roamingSettings.get("MetadactConfig");

  let config: Config;

  if (cachedConfig === undefined) {
    config = await fetchConfig();
    await cacheConfig(config);
  } else {
    let serverConfig = await fetchConfig();
    if (serverConfig.persistUserChanges) {
      persistUserFields.forEach((field) => {
        serverConfig[field] = cachedConfig[field];

        if (field === "enableCleaning") {
          serverConfig[field] = cachedConfig[field];
        }
      });
    }
    config = serverConfig;
    await cacheConfig(serverConfig);
  }

  return config;
}

export async function getCacheConfig(): Promise<Config> {
  const cachedConfig = Office.context.roamingSettings.get("MetadactConfig");

  let config: Config;

  if (cachedConfig === undefined) {
    config = await fetchConfig();
    await cacheConfig(config);
  } else {
    config = cachedConfig;
  }
  return config;
}

export async function cacheConfig(newConfig) {
  Office.context.roamingSettings.set("MetadactConfig", newConfig);
  await saveRoamingSettings();
}

export async function cacheCustomPropAttachment(attachment) {
  let metadactAttachments = await getCachedAttachments();
  metadactAttachments.push(attachment);
  customProps.set("MetadactAttachments", metadactAttachments);
  await saveCustomProp(customProps);
}

export async function cacheCustomPropAttachments(attachments) {
  customProps.set("MetadactAttachments", attachments);
  await saveCustomProp(customProps);
}

export async function getCachedAttachments() {
  const result = await loadCachedCustomProperties();
  customProps = result.value;
  const metadactAttachments = customProps.get("MetadactAttachments");
  return metadactAttachments !== undefined ? metadactAttachments : [];
}

export async function cacheSendEventTimestamp(timestamp: number) {
  Office.context.roamingSettings.set("SendEventTimestamp", timestamp.toString());
  await saveRoamingSettings();
}

export function getCachedSendEventTimestamp() {
  const sendEventTimestamp = Office.context.roamingSettings.get("SendEventTimestamp");
  return sendEventTimestamp ? Number(sendEventTimestamp) : null;
}

export async function clearCachedSendEventTimestamp() {
  Office.context.roamingSettings.remove("SendEventTimestamp");
  await saveRoamingSettings();
}
